$enable-negative-margins : true;

$spacer: 1rem;
$spacers: (
        0: 0,
        3: 0.3rem,
        4: 0.4rem,
        5: 0.5rem,
        7: 0.7rem,
        8: 0.8rem,
        10: 1rem,
        12: 1.2rem,
        15: 1.5rem,
        16: 1.6rem,
        18: 1.8rem,
        19: 1.9rem,
        20: 2rem,
        24: 2.4rem,
        27: 2.7rem,
        30: 3rem,
        32: 3.2rem,
        33: 3.3rem,
        35: 3.5rem,
        40: 4rem,
        43: 4.3rem,
        44: 4.4rem,
        48: 4.8rem,
        50: 5rem,
        52: 5.2rem,
        55: 5.5rem,
        61: 6.1rem,
        64: 6.4rem,
        72: 7.2rem,
        80: 8rem,
        95: 9.5rem,
        96: 9.6rem,
        100: 10rem,
        122: 12rem,
        127: 12.7rem,
        236: 23.6rem,
);