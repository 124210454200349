$white:    #fff;
$black:    #000;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$gray-1000: #4D505B;
$gray-1100: #D9D9D9;


$blue:    #0C4588;
$indigo:  #6610f2;
$purple:  #6f42c1;
$pink:    #d63384;
$red:     #dc3545;
$orange:  #fd7e14;
$yellow:  #ffc107;
$green:   #198754;
$teal:    #20c997;
$cyan:    #0dcaf0;

$green1:          #32B4B4;
$green2:          #0E6D83;
$blue1:          #194270;
$blue2:          #35689A;
$blue3:          #24416D;
$blue4:          #1A3257;
$gray:          #ECF0F4;
$gray1:          #D0D0D0;
$gray2:          #555555;
$gray3:          #A0B4C3;
$blue-green-gradiant: linear-gradient(66.57deg, #194270 0%, #32B4B4 50%, #C8E6CD 100%);

$primary : $green;
$secondary : $blue4;
$success:       $green;
$info:          $cyan;
$warning:       $yellow;
$danger:        $red;
$light:         $gray-100;
$dark:          #0F1A2A;

$theme-colors: ();
$theme-colors: map-merge(
                (
                        "primary":    $primary,
                        "secondary":  $secondary,
                        "success":    $success,
                        "info":       $info,
                        "warning":    $warning,
                        "danger":     $danger,
                        "light":      $light,
                        "dark":        $dark,
                        "black":       $black,
                        "white":       $white,
                        "green":       $green,
                        "green1":       $green1,
                        "green2":       $green2,
                        "blue1":       $blue1,
                        "blue2":       $blue2,
                        "blue3":       $blue3,
                        "blue4":       $blue4,
                        "gray":       $gray,
                        "gray1":       $gray1,
                        "gray2":       $gray2,
                        "gray3":       $gray3,
                ),
                $theme-colors
);

/* ======= utilities
*/

$utilities: map-merge(
                $utilities,(
                "border-color": (
                        property: border-color,
                        class: border,
                        local-vars: (
                                "border-opacity": 1
                        ),
                        values: $theme-colors
                ),
        )
);