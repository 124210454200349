// Mettre à jour ce lien via : https://fonts.google.com/

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

$line-height-base:            2.7;

/* ======= fonts à déclarer
*/
$fonts: ();
$fonts: (
        roboto:'roboto',
        inter:'inter',
);

$font-family-base: map-get($fonts,inter);
$font-size-base : 1.6rem;

$font-sizes: ();
$font-sizes: (
        12: 1.2rem,
        14: 1.4rem,
        16: 1.6rem,
        20: 2rem,
        24: 2.4rem,
        36: 3.6rem,
        40: 4.0rem,
        50: 5.0rem,

);

/* ======= fonts weight
*/

$font-weight-lighter:         lighter;
$font-weight-light:           300;
$font-weight-normal:          400;
$font-weight-medium:          500;
$font-weight-semibold:        600;
$font-weight-bold:            700;
$font-weight-black:          800;
$font-weight-bolder:          bolder;

$font-weight-base:            $font-weight-normal;

/* ======= line height
*/

$line-height: ();
$line-height: (
        20: 2rem,
        24: 2.4rem,
        28: 2.8rem,
        42: 4.2rem,
        48: 4.8rem,
);

/* ======= letter spacing
*/

$letter-spacing: ();
$letter-spacing: (
        28: 2.8px,
);

/* ======= utilities
*/

$utilities: map-merge(
                $utilities,(
                "font-family":(
                        rfs:true,
                        property: font-family,
                        responsive: true,
                        class: font,
                        values: $fonts
                ),
                "font-size": (
                        rfs: true,
                        property: font-size,
                        responsive: true,
                        class: fs,
                        values: $font-sizes
                ),
                "font-weight": (
                        property: font-weight,
                        class: fw,
                        responsive: true,
                        values: (
                                light: $font-weight-light,
                                normal: $font-weight-normal,
                                medium: $font-weight-medium,
                                semi-bold: $font-weight-semibold,
                                bold: $font-weight-bold,
                                black: $font-weight-black
                        )
                ),
                "line-height": (
                        property: line-height,
                        class: lh,
                        responsive: true,
                        values: $line-height
                ),
                "text-color": (
                        property: color,
                        class: text,
                        responsive: true,
                        values: $theme-colors
                ),
                "letter-spacing": (
                        property: letter-spacing,
                        class: letter-spacing,
                        responsive: true,
                        values: $letter-spacing
                )
        )
);
